import { TemplateQuestionPanelState, Tab, TabType, Questionnaire, overrideByTabConfiguration } from '@dims/components';

const customerSpecificationTabs: Tab[] = [
  {
    title: 'Opgavebeskrivelse',
    section: 0,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Opgavekøb',
    section: 1,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Kompetencekøb',
    section: 2,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Option(er)',
    section: 3,
    panelState: TemplateQuestionPanelState.INIT,
  },
  {
    title: 'Øvrige bilag til leveringskontrakten',
    section: 4,
    panelState: TemplateQuestionPanelState.INIT,
  },
];

type TabNumbers = [number, number, number];

function supplierOfferTabs(p: TabNumbers) {
  const tabs = [
    {
      title: 'Opgavekøb',
      section: p[0],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Kompetencekøb',
      section: p[1],
      panelState: TemplateQuestionPanelState.INIT,
    },
    {
      title: 'Option(er)',
      section: p[2],
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
  return tabs.filter((t) => t.section !== -1);
}

const deliverySectionKey = '1713DeliverySection';
const competenceSectionKey = '1713CompetenceSection';
const optionsSectionKey = '1713OptionSection';
const optionsSelectKey = '1713OptionSelect';

let supplierOfferTabNumbers = new Array(3) as TabNumbers;

/** The index of the question with the given key */
function sectionIndex(offerQuestionnaire: Questionnaire | null, key: string) {
  if (offerQuestionnaire) {
    return offerQuestionnaire.questions.findIndex((q) => q.key === key);
  }
  return -1;
}

export function setSupplierPriceTabs(offerQuestionnaire: Questionnaire|null):number[] {
  const deliverySectionId = sectionIndex(offerQuestionnaire, deliverySectionKey);
  let deliverySectionIdForDisplay = -1;
  const deliverySection = offerQuestionnaire?.questions[deliverySectionId];
  if (deliverySection?.questions && deliverySection.questions.length > 1) deliverySectionIdForDisplay = deliverySectionId;

  const competenceSectionId = sectionIndex(offerQuestionnaire, competenceSectionKey);
  let competenceSectionIdForDisplay = -1;
  const competenceSection = offerQuestionnaire?.questions[competenceSectionId];
  if (competenceSection?.questions && competenceSection.questions.length > 1) competenceSectionIdForDisplay = competenceSectionId;

  const optionsSectionId = sectionIndex(offerQuestionnaire, optionsSectionKey);
  let optionsSectionIdForDisplay = -1;
  const optionsSection = offerQuestionnaire?.questions[optionsSectionId];
  if (optionsSection?.questions && optionsSection.questions.length > 0) {
    const optionsSelected = optionsSection.questions.find((q) => q.key === optionsSelectKey);
    if (optionsSelected && (optionsSelected.text?.endsWith('Ja') ?? false)) optionsSectionIdForDisplay = optionsSectionId;
  }

  supplierOfferTabNumbers = [
    deliverySectionIdForDisplay,
    competenceSectionIdForDisplay,
    optionsSectionIdForDisplay,
  ];
  return supplierOfferTabNumbers;
}

const questionnaireTabConfig = {
  getTabs: async (tenderId: string, type: TabType): Promise<Tab[]> => {
    switch (type) {
      case 'CustomerSpecification':
        return await overrideByTabConfiguration(tenderId, type, customerSpecificationTabs);
      case 'SupplierOffer':
        return await overrideByTabConfiguration(tenderId, type, supplierOfferTabs(supplierOfferTabNumbers));
      default:
        return [];
    }
  },
};

export default questionnaireTabConfig;
