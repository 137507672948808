<template>
  <div>
    <AppLayout />
  </div>
</template>

<script setup lang="ts">
import { provide } from 'vue';
import { AppLayout, injectionKeys } from '@dims/components';
import { getCalculator, tenderDisplay, prefills, offerService, materialsConfig, questionnaireTabConfig } from '@/services';
import componentProvider from '@/componentProvider';
import agreementSpecific from '@/agreementSpecific';
import requestQuestionnaireProviderFactory from '@/components/Tender/TenderMaterial/requestQuestionnaireProviderFactory';

provide(injectionKeys.componentProviderKey, componentProvider);
provide(injectionKeys.agreementSpecificKey, agreementSpecific);
provide(injectionKeys.tenderDisplayKey, tenderDisplay);
provide(injectionKeys.calculatorKey, getCalculator());
provide(injectionKeys.offerValidatorKey, offerService);
provide(injectionKeys.prefillsKey, prefills);
provide(injectionKeys.materialsConfigKey, materialsConfig);
provide(injectionKeys.questionnaireTabConfigKey, questionnaireTabConfig);
provide(injectionKeys.requestForOfferQuestionnaireKey, requestQuestionnaireProviderFactory);

</script>
