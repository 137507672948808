/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
const deliveryAgreementIds = {
  idDeliveryDate: '8b238ee9-9354-41ae-90ce-6401b6bf4a21',
  // Criteria weights:
  idCompetenceWeight: '7c86b3f5-2c2e-4294-8a98-9c6010158b61',
  idSolutionWeight: 'a108a89c-9557-4f93-9ab9-c6efb80cf9a8',
};
export default deliveryAgreementIds;
