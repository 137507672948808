// This file contains configuration of the "Opsummering" view for the supplier.
// It lists question id's of questions on the second level in the questionnaire template
// to be excluded from the "Opsummering" view. Questions not in this list will be
// shown in the "Opsummering" view.

const excludedQuestions = [
  // Kategori 1
  'e27b80b0-fe93-4ded-8eca-3ca16ec02633', // Vejledning
  '73235af7-f4c2-4717-afcb-8d57f410a625', // Motivation
  // Kategori 2
  'cb469b45-d1e6-4bf5-91cd-f261a344a6e7', // Vejledning
  '133c565a-2734-4be5-b54b-111cd8d9535a', // Løsningsmodel
  '1efbb112-c80b-4c3e-92ee-eca23b63877a', // Motivation
  'e436839a-9784-41d2-88b4-042034bdd571', // Optioner
];

export default excludedQuestions;
