import { OfferEvaluation, OfferEvaluationData } from '@dims/components';
import { BpqScores, OfferData1713 } from '@/models/Offer';
import { BpqCriteriaPoints, TenderData1713 } from '@/models/Tender';

export type BPQCriteriaEnum =
 | 'Competence'
 | 'Solution'

const categories: [BPQCriteriaEnum, string][] = [
  ['Competence', 'Kompetencer'],
  ['Solution', 'Ydelsesspecifik løsningsbeskrivelse'],
];

export default {
  getCategories(tenderData: TenderData1713, offerEvaluation: OfferEvaluation) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;

        // Verbose version to avoid linting errors
        let score = 0;
        let reason = '';

        if (offerEvaluation.data) {
          const offerEvaluationElement = this.getOfferEvaluationElement(offerEvaluation.data, key);

          if (offerEvaluationElement) {
            score = offerEvaluationElement.score ?? 0;
            reason = offerEvaluationElement.reason ?? '';
          }
        }

        return {
          key,
          label,
          score,
          weight: this.getWeight(tenderData, key) ?? 0,
          reason,
        };
      });
  },

  getEmptyCategories(tenderData: TenderData1713) {
    return categories
      .filter((c) => {
        const [key, _label] = c;
        return this.isScoreIncluded(tenderData, key);
      })
      .map((c) => {
        const [key, label] = c;
        return {
          key,
          label,
          score: 0,
          weight: 0,
          reason: '',
        };
      });
  },
  getOfferEvaluationElement(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
    return offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);
  },
  getWeight(tenderData: TenderData1713, key: BPQCriteriaEnum) {
    const propertyName: keyof BpqCriteriaPoints = `bpqCriteria${key}`;
    return tenderData[propertyName];
  },
  isScoreIncluded(tenderData: TenderData1713, key: BPQCriteriaEnum) {
    return tenderData.bpqCriteria?.includes(key);
  },
  getScorePropertyName(key: BPQCriteriaEnum): keyof BpqScores {
    return `bpq${key}Score`;
  },
  getOfferScore(offerData: OfferData1713, key: BPQCriteriaEnum) {
    const propertyName = this.getScorePropertyName(key);
    return offerData[propertyName];
  },
  setOfferScore(offerData: OfferData1713, key: BPQCriteriaEnum, value: number) {
    const scorePropertyName = this.getScorePropertyName(key);
    // eslint-disable-next-line no-param-reassign
    offerData[scorePropertyName] = value;
  },
};
