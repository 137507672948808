<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync
-->
<template>
  <div>
    <v-form ref="form" :disabled="deadlineExpired">
      <v-container fluid>
        <div class="align-self-center mb-5" v-if="isDraft && deadlineExpired">
          <p class="text-black ma-0">
            Du kan ikke indsende tilbud, da tilbudsfristen er overskredet
          </p>
        </div>
        <div v-if="offerPublishedDate !== ''" class="mb-10">
          <p>
            <strong>Tilbud indsendt {{ offerPublishedDate }}</strong>
          </p>
          <div>
            <p v-if="!deadlineExpired">
              Du kan frem til tilbudsfristen trække dit tilbud tilbage og evt.
              tilrette og indsende et nyt. Det gør du under fanen 'Overblik'.
              Når tilbudsfristen er udløbet, kan du ikke længere ændre eller
              tilbagekalde dit tilbud
            </p>
          </div>
          <v-btn
            class="primary-button"
            @click="offerDetailsDialog = !offerDetailsDialog"
          >
            Se dit tilbud
          </v-btn>
          <v-dialog v-model="offerDetailsDialog" width="1400">
            <v-card v-if="offerDetailsDialog" class="bg-canvas px-5 pt-10">
              <v-card-text>
                <OfferSpecificationViewer :tender :offer />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  @click="closeOfferDetailsDialog()"
                  class="mb-4 primary-button"
                >
                  Luk
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-row>
          <v-col>
            <v-card class="pa-3 bg-canvas">
              <v-card-text>
                <div class="d-flex">
                  <div>
                    <h1 class="mt-0 mb-2 font-weight-bold">
                      Udfyld dit tilbud
                    </h1>
                    <div class="mt-2 mb-7">
                      <p>
                        Kravbesvarelse og prisafgivelse herunder udgør dit
                        tilbud
                      </p>
                      <p>
                        Færdiggør dit tilbud ved at besvare (1) udfyld tilbud, (2) bekræfte at dit tilbud opfylder kundens krav, (3) kontroller og send dit tilbud.
                      </p>
                      <p v-if="isDraft && error" class="text-error">
                        Kan ikke gemme kladde!
                      </p>
                    </div>
                  </div>
                </div>
                <v-card>
                  <v-card-text class="pa-10">
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="1"
                          :complete="isSectionComplete(priceSections)"
                        />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Tilbudsafgivelse</h2>
                        <p>
                          Forud for tilbudsafgivelsen skal du orientere dig i kundens offentliggjorte materiale, som du finder under "overblik"
                        </p>
                        <p>
                          Du udfylder dit tilbud ved at besvare kundens behovsopgørelse, tilbyde relevante konsulenter (herunder uploade CV) samt afgive priser.
                        </p>
                        <p>
                          Du vil løbende blive guidet til at udfylde de relevante felter og vejledt om, hvor du finder de relevante informationer i kundens offentliggjorte materiale.
                        </p>
                        <IconExplanation :isCustomer="false" />
                        <v-dialog v-model="offerFormDialog" max-width="95%">
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!offerQuestionnaire || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              :class="{ secondary: isSectionComplete(priceSections) }"
                            >Udfyld tilbud</ArrowButton>
                          </template>
                          <v-card>
                            <OfferDetailsForm
                              v-if="offerQuestionnaire"
                              :tender
                              :offerQuestionnaire
                              :tabType="'SupplierOffer'"
                              @closeDialog="closeOfferFormDialog"
                              :offerId="offer.id"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="2"
                          :complete="specificationAccepted === true || specificationAccepted === false"
                        />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                        <div>
                          Bekræft om dit tilbud opfylder samtlige krav i kundens
                          kravspecifikation.
                        </div>
                        <v-radio-group
                          v-model="specificationAccepted"
                          hide-details
                          :disabled="!isDraft"
                          density="compact"
                        >
                          <v-radio :value="true">
                            <template #label>
                              <small>Ja, alle krav er opfyldt</small>
                            </template>
                          </v-radio>
                          <v-radio :value="false">
                            <template #label>
                              <small>
                                Nej, alle krav er
                                <span class="font-weight-bold">ikke</span>
                                opfyldt
                              </small>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <div
                          v-if="specificationAccepted === false"
                          class="text-red"
                        >
                          <strong>Bemærk:</strong> du har angivet, at dit tilbud
                          ikke opfylder kravene i kravspecifikationen, dit
                          tilbud vil derfor som udgangspunkt blive vurderet som
                          ukonditionsmæssigt.
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus :label="3" :complete="espdFile !== undefined" />
                      </v-col>
                      <v-col>
                        <OfferDetailsESPD
                          :tender
                          :offer
                          :disabled="!validOffer || !isDraft"
                          :espdFile
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus :label="4" :complete="!isDraft" />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                        <p v-if="!validOffer && isDraft">Færdiggør trin 1-3, for at sende dit tilbud</p>
                        <v-dialog
                          v-model="confirmationOfferDialog"
                          persistent
                          scrollable
                          max-width="80%"
                        >
                          <template #activator="{ props }">
                            <ArrowButton
                              data-cy="send-offer-button"
                              :disabled="!validOffer || !isDraft"
                              v-bind="props"
                              class="mt-2"
                            >Se og send tilbud</ArrowButton>
                          </template>
                          <v-card>
                            <SendOfferConfirmationDialog
                              @closeConfirmationDialog="closeOfferConfirmationDialog"
                              @makeOfferActive="makeOfferActive"
                              :offer
                              :tender
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <TcoCalculationSummary
              :questions="calculationSummaryQuestions"
              :tcoCalculationResult
              cardStyle="#fff9ef"
              tableStyle="transparent"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <LoadingSpinner :loadingMessage :visible="isLoading || isSaving" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  UpdateOfferRequest,
  useStore,
  SendOfferConfirmationDialog,
  useBackend,
  dateUtils,
  Questionnaire,
  ArrowButton,
  tenderHelper,
  StepStatus,
  OfferDetailsForm,
  useDebouncedOfferQuestionnaire,
} from '@dims/components';
import OfferSpecificationViewer from '../Specification/OfferSpecificationViewer.vue';
import TcoCalculationSummary from './TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTabs } from '../../../services/questionnaireTabConfig';
import excludedQuestions from '@/services/offerSummaryConfig';

const { tender, offer } = defineProps<{ tender: Tender, offer: Offer }>();
const store = useStore();
const backend = useBackend();
const confirmationOfferDialog = ref(false);
const offerDetailsDialog = ref(false);
const offerFormDialog = ref(false);
const loadingMessage = ref('Vent venligst');
const isLoading = ref(false);
const isSaving = ref(false);
const error = ref(false);
const priceSections = ref<number[]>([]);
const offerQuestionnaire = useDebouncedOfferQuestionnaire(tender.id, offer.id, store.backend, afterQuestionnaireUpdated);

// Callback when questionnaire has changed
function afterQuestionnaireUpdated(questionnaire: Questionnaire) {
  priceSections.value = setSupplierPriceTabs(questionnaire);
  priceSections.value = priceSections.value.filter((section) => section !== -1);
}

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const calculationSummaryQuestions = computed(
  () => offerQuestionnaire.value?.questionnaire.questions.filter((x, idx) => priceSections.value.includes(idx) && x.questions).flatMap((x) => x.questions ?? [])
    .filter((q) => !excludedQuestions.includes(q.questionId)) ?? [],
);

const deadlineExpired = computed(() => tenderHelper.isDeadlineExpired(tender));

function closeOfferConfirmationDialog() {
  confirmationOfferDialog.value = false;
}

function closeOfferDetailsDialog() {
  offerDetailsDialog.value = false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

async function makeOfferActive(signerName: string) {
  closeOfferConfirmationDialog();
  if (offer.status === 'Draft' && signerName) {
    const update: UpdateOfferRequest = {
      data: {},
      signerName,
    };
    loadingMessage.value = 'Sender tilbud';
    isLoading.value = true;
    try {
      await store.updateOfferAction(offer, update);
      await store.publishOfferAction(offer);
      await backend.markerService.createMarker(offer);
    } catch (err) {
      console.error(err);
      store.setSnackbarText('Kan ikke sende tilbud');
    } finally {
      isLoading.value = false;
    }
    loadingMessage.value = 'Vent venligst';
  }
}

async function acceptSpecification() {
  console.info('acceptSpecification');
  offer.specificationAccepted = true;
  await saveDraftNow();
}

async function rejectSpecification() {
  console.info('rejectSpecification');
  offer.specificationAccepted = false;
  await saveDraftNow();
}

const specificationAccepted = computed({
  get(): boolean | undefined {
    return offer.specificationAccepted ?? undefined;
  },
  set(value: boolean | undefined) {
    if (value) {
      void acceptSpecification();
    } else {
      void rejectSpecification();
    }
  },
});

async function saveDraftNow() {
  console.info('saving offer');
  isSaving.value = true;
  error.value = false;
  try {
    const update: UpdateOfferRequest = {
      specificationAccepted: offer.specificationAccepted,
    };
    await store.updateOfferAction(offer, update);
    console.info('offer saved');
  } catch (err) {
    error.value = true;
    console.error('Could not save offer draft', err);
  } finally {
    isSaving.value = false;
  }
}

const validOffer = computed(() => (
  !tenderHelper.isDeadlineExpired(tender)
      && (specificationAccepted.value === true
        || specificationAccepted.value === false)
      && offerQuestionnaire.value?.complete
));

const isDraft = computed((): boolean => offer.status === 'Draft');

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

const espdFile = computed(() => offer.artifacts?.find((x) => x.templateArtifactKey === 'espd' && x.templateKey === 'offer'));

</script>
