import { Offer, AnswerSetBuilder } from '@dims/components';
import { Offer1713, OfferData1713, Tender1713 } from '@/models';
import { getCalculator } from './utils1713';

export default {
  getOfferPrice(offer: Offer): number {
    return offer.contractSum ?? 0;
  },

  offerIsUnconditional(offer: Offer, tender: Tender1713): boolean {
    return offer.status === 'Unconditional'
      || (offer.isUnconditional ?? false)
      || getCalculator().isScoreBelowConditionalThreshold_BackendCalculation(offer)
      || getCalculator().isScoreBelowConditionalThreshold_FrontendCalculation(offer, tender);
  },

  applyTerminationLetterAgreementSpecific(
    answers: AnswerSetBuilder,
    offer: Offer1713,
    tender: Tender1713,
    winningOffer: Offer1713 | undefined,
    _conditionalOffers: Offer[],
    _isUnconditional: boolean,
  ) {
    if (winningOffer) {
      // Winning offers price
      answers.addCurrency(
        '2bc54322-827e-41c8-a1a8-e5c57d3f2bd3',
        winningOffer.data.tcoCalculationResult?.tcoPrice ?? 0,
      );

      // Winner's scores
      // Quality score
      answers.addNumber('33a23417-fdf8-4b6b-a921-06f4424ce6f9', winningOffer.data.bpqPointScore ?? 0);
      // Winner price per point
      answers.addCurrency('1cb29e8a-93b4-4514-8454-4fd8f3cf18b6', winningOffer.data.bpqScore ?? 0);
    } else {
      // Numeric fields cannot be empty, otherwise DD will not accept the questionnaire as valid
      answers.add('2bc54322-827e-41c8-a1a8-e5c57d3f2bd3', '-');
    }
    // Offer price
    answers.addCurrency('6298432b-ebc0-4f9e-ae2d-fce9ec956565', offer.data.tcoCalculationResult?.tcoPrice ?? 0);
    // Offer's quality score
    answers.addNumber('b41cc488-c4e0-4542-a094-caee2f3971e9', offer.data.bpqPointScore ?? 0);
  },

  /* @typescript-eslint/no-empty-function */
  applyEvaluationReportAgreementSpecific(
    answers: AnswerSetBuilder,
    tender: Tender1713,
    conditionalOffers: Offer[],
    unconditionalOffers: Offer[],
    cvrNumbers: Record<string, string>,
  ) {
    // Sets count for the offer-repeater
    // (This is a special-case hidden question)
    answers.addNumber(
      'ed80cdfe-d377-4402-b52e-d6d6c36bee08',
      conditionalOffers.length + unconditionalOffers.length,
    );

    // Indkomne tilbud (repeater ID's, will be postfixed with index)
    const companyNameQuestionId = '234c1e86-7b17-4f52-9b19-3df4ec7ee7f4';
    const companyCvrQuestionId = '9c34c3a2-9769-4299-8d30-3ddb7a042c96';
    const conditionalQuestionId = '34202a02-e5d1-430b-a158-a0bb94d1c39b';
    const conditionalTrueAnswerId = '6a4d2959-9539-40df-9287-fcf0e9af95d9';
    const conditionalFalseAnswerId = 'f5024533-1cef-426d-a0b0-c7b882757c33';
    const tcoPriceQuestionId = '034e24c1-5538-4128-a9ef-5ad35a2d7d96';
    const apvScoreQuestionId = '7a0ed686-0060-41da-b4c3-c3383b709a76';
    const totalScoreQuestionId = 'a2089ef5-2dca-4e19-9d19-dfc208ca4315';

    const espdDocumentationApprovedQuestionId = '808fed60-eb55-4703-8a94-6d922fac771b';
    const espdDocumentationApprovedTrueAnswerId = '7a5f9f96-61a0-43b5-ad22-b4b22111084e';
    const espdDocumentationApprovedFalseAnswerId = 'b00d6794-0d40-471e-bb3e-9090f19736d0';

    // All offers
    const allOffers: Offer[] = [];
    allOffers.push(...conditionalOffers, ...unconditionalOffers);
    /* A repeater for all offers. Winning offer is special cased.
      If question ID's for winning offer is foo, other offers are foo_1, foo_2 etc.
    */
    allOffers.forEach((o: Offer, index: number) => {
      const od = o.data as OfferData1713;
      if (index === 0) {
        answers.add(companyNameQuestionId, o.sender.companyName);
        answers.add(companyCvrQuestionId, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          conditionalQuestionId,
          this.offerIsUnconditional(o, tender)
            ? conditionalFalseAnswerId : conditionalTrueAnswerId,
        );
        answers.addCurrency(
          tcoPriceQuestionId,
          od.tcoCalculationResult?.tcoPrice ?? -1,
        );
        answers.addNumber(
          apvScoreQuestionId,
          od.bpqPointScore ?? -1,
        );
        answers.addCurrency(
          totalScoreQuestionId,
          od.bpqScore ?? -1,
        );
      } else {
        answers.add(`${companyNameQuestionId}_${index}`, o.sender.companyName);
        answers.add(`${companyCvrQuestionId}_${index}`, cvrNumbers[o.sender.organizationNumber] ?? '');
        answers.addCheck(
          `${conditionalQuestionId}_${index}`,
          this.offerIsUnconditional(o, tender)
            ? `${conditionalFalseAnswerId}_${index}` : `${conditionalTrueAnswerId}_${index}`,
        );
        answers.addCurrency(
          `${tcoPriceQuestionId}_${index}`,
          od.tcoCalculationResult?.tcoPrice ?? -1,
        );
        answers.addNumber(
          `${apvScoreQuestionId}_${index}`,
          od.bpqPointScore ?? -1,
        );
        answers.addCurrency(
          `${totalScoreQuestionId}_${index}`,
          od.bpqScore ?? -1,
        );
      }
    });
    const winner = conditionalOffers[0];
    answers.addCheck(
      espdDocumentationApprovedQuestionId,
      winner?.documentationApproved
        ? espdDocumentationApprovedTrueAnswerId : espdDocumentationApprovedFalseAnswerId,
    );
  },
};
