import { DraftTender, Tender, TenderData } from '@dims/components';
import { BPQCriteriaEnum } from '@/services/bpqCriteria';

export enum SolutionType {
   Category1 = 'Category1',
   Category2 = 'Category2'
}

export interface BpqCriteriaPoints {
  bpqCriteriaCompetence?: number;
  bpqCriteriaSolution?: number;
}

export interface TenderData1713 extends TenderData, BpqCriteriaPoints {
  bpqCriteria?: BPQCriteriaEnum[];
  bpqPointScoreMinimum?: number;
  solutionType?: SolutionType;
  serviceAreas?: string;
  estimatedValue?: number;
}

export interface Tender1713 extends Tender {
  readonly data: TenderData1713;
}

export interface DraftTender1713 extends DraftTender {
  readonly data: TenderData1713;
}
