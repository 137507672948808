import { useStore } from '@dims/components';
import { defineStore } from 'pinia';
import { DraftTender1713 } from '@/models/Tender';

export const useStore1713 = defineStore('1713', {
  getters: {
    draftTender() {
      const store = useStore();
      return store.draftTender as DraftTender1713 | null;
    },
  },
  actions: {
    setDraftTender(tender: DraftTender1713) {
      const store = useStore();
      store.setDraftTender(tender);
    },
  },
});

export default useStore1713;
