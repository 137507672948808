<template>
  <CreateTenderDialog
    v-if="store.myTendersFetched"
    :tender
    :tender-stub="tenderStub"
    :create-button-disabled="isCreateTenderButtonDisabled()"
    :createAsTemplate
  >
    <template #create-tender-wizard-step>
      <CreateTenderWizardStep :createAsTemplate></CreateTenderWizardStep>
    </template>
  </CreateTenderDialog>
</template>

<script setup lang="ts">
import { Tender, useStore } from '@dims/components';
import CreateTenderWizardStep from '@/components/create-tender/CreateTenderWizardStep.vue';
import { useStore1713 } from '@/store/store1713';
import { tenderStub as tenderStubDefault } from '@/services';

const { tender = null, createAsTemplate = false } = defineProps<{
  /** Only when the create/clone button is on an existing tender */
  tender?: Tender | null,
  createAsTemplate?: boolean }>();
const store = useStore();
const store1713 = useStore1713();

function isCreateTenderButtonDisabled() {
  return !store1713.draftTender?.description;
}

const tenderStub = { ...tenderStubDefault, agreementName: store.agreementId };

</script>
